<template>
  <section style="width: 100%; height: 79vh">
    <iframe
      style="width: 100%; height: 100%; border: 0; background-color: white"
      src="https://docs.google.com/document/d/e/2PACX-1vRywlwwa_5s7jm5M3LsfI1LANlmfGuLJbAod-CqyAK7DcKVFCSlu7zVp2vLwsIR2kyFUxTJaMh8Tfgh/pub?embedded=true"
    />
  </section>
</template>

<script>
export default {}
</script>

<style></style>
